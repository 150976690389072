.p-home-top {
	padding-top: 65px;
	padding-bottom: 265px;
}

.p-home-top-c {
	display: flex;
}

.p-home-top-l {
	flex: 0 0 auto;
	width: 515px;
	max-width: 100%;
	position: relative;
	z-index: 1;
}

.p-home-top-r {
	position: relative;
	flex: 1;
	min-width: 0;
	padding-right: 70px;
}

.p-home-top__title {
	font-size: 56px;
	line-height: 58px;
	font-weight: bold;
	margin-bottom: 15px;
}

.p-home-top__desc {
	font-size: 14px;
	line-height: 22px;
	color: rgba(#fff, 0.35);
	max-width: 430px;
}

.p-home-top-r__img-container {
	position: relative;
	width: 395px;
	height: 395px;
	margin-left: auto;
	margin-top: -155px;
	pointer-events: none;
}

.p-home-top-r__img-light {
	display: none;
	position: absolute;
	width: auto;
	height: auto;
	border: 1px solid red;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.p-home-top-r__img {
	max-width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
}

.p-home-top-r__img-puzzle-bg {
	position: absolute;
	height: auto;
	display: block;
	top: -82px;
	left: -847px;
}

@keyframes testAnimation {
  from {
    opacity: 0;
    transform: scale3d(2, 2, 2);
  }

  95% {
    opacity: 1;
  }
}

.testAnimation {
  animation-name: testAnimation;
}