@media (max-width: 1249px) {
	.p-home-top-r {
		padding-right: 0;
	}

	.categories__item,
	.recommend__item {
		padding-right: 10px;
		padding-left: 10px;
	}

	.categories__list,
	.recommend__list {
		margin-left: -10px;
		margin-right: -10px;
	}
}

@media (max-width: 991px) {
	.p-home-top {
		padding-top: 15px;
		padding-bottom: 25px;
	}

	.p-home-top-l {
		width: 395px;
	}

	.p-home-top__title {
		font-size: 46px;
		line-height: 48px;
	}

	.p-home-top__desc {
		font-size: 13px;
	}

	.p-home-top-r__img-container {
		margin-top: -90px;
		transform: scale(0.7);
		transform-origin: top left;
	}

	.header-top {
		padding-top: 25px;
		padding-bottom: 75px;
	}

	.section-title {
		font-size: 32px;
	}

	.categories__list,
	.recommend__list {
		margin-bottom: -40px;
		margin-left: -20px;
		margin-right: -20px;
	}

	.categories__item,
	.recommend__item {
		width: 50%;
		margin-bottom: 40px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.categories {
		padding-bottom: 100px;
	}

	.recommend {
		padding-bottom: 80px;
	}

	.footer {
		padding: 40px 0 40px;
	}

	.header-fixed__nav-link {
		height: 70px;
		padding: 0 18px;
		font-size: 13px;
	}

	.header-fixed__nav-link-img-c {
		margin-right: 11px;
	}

	.header-fixed__nav-link-img {
		width: 20px;
	}
}

@media (max-width: 767px) {
	.p-home-top-c {
		flex-direction: column-reverse;
		flex-wrap: wrap;
	}

	.p-home-top-l {
		flex: 0 0 auto;
		width: 100%;
	}

	.p-home-top-r {
		flex: 0 0 auto;
		width: 100%;
		margin-bottom: 35px;
	}

	.p-home-top-r__img-puzzle-bg {
		display: none;
	}

	.header-top {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.p-home {
		padding-top: 25px;
	}

	.p-home-top__title {
		text-align: center;
		font-size: 36px;
		line-height: 40px;
	}

	.p-home-top__desc {
		text-align: center;
		max-width: 100%;
	}

	.p-home-top-r__img-container {
		width: 135px;
		height: 135px;
		margin-right: auto;
		transform-origin: top center;
		transform: none;
		margin-top: 0;
	}

	.section-title {
		font-size: 28px;
	}

	.p-home-top {
		padding-bottom: 70px;
	}

	.categories__item-img-container {
		margin-bottom: 25px;
	}

	.categories__item-desc {
		margin-bottom: 40px;
	}

	.categories {
		padding-bottom: 60px;
	}

	.header-fixed__nav-list {
		display: none;
	}

	.header-fixed-c {
		height: 50px;
	}

	.header-fixed-l {
		padding-left: 15px;
	}

	.header-fixed__menu-toggler {
		position: relative;
		display: block;
		outline: none;
		border: none;
		border-radius: 0;
		height: 35px;
		width: 35px;
		padding: 0;
		cursor: pointer;
		margin-right: 10px;

		&:focus {
			outline: none;
		}
	}

	.sandwich {
		width: 19px;
		height: 17px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 1;
		display: block; 

		.sw-topper,
		.sw-bottom,
		.sw-footer {
			position: absolute;
			width: 20px;
			height: 3px;
			background: #080C19;
			border: none;
		}
	}

	.sandwich .sw-topper {
		top: 14px;
		transition: transform 0.5s, top 0.2s; 
	}

	.sandwich .sw-bottom {
		top: 7px;
		transition: transform 0.5s, top 0.2s;
		transition-delay: 0.2s, 0s; 
	}

	.sandwich .sw-footer {
		top: 0px;
		transition: all 0.5s;
		transition-delay: 0.1s;
	}

	.header-fixed__mob-menu {
		position: fixed;
		z-index: 100;
		top: 0;
		bottom: 0;
		right: 0;
		width: 225px;
		transform: translateX(105%);
		transition: 0.5s;
		display: flex;
		flex-direction: column;
		background: #080C19;

		&.active {
			transform: translateX(0);
		}
	}

	.header-fixed__mob-menu-c {
		display: flex;
		flex-direction: column;
		padding: 11px 15px 15px 15px;;
		align-items: flex-end;
		height: 100%;
		overflow-y: auto;
		flex: 1;
	}

	.header-fixed__mob-menu-close-btn {
		position: relative;
		display: inline-block;
		width: 25px;
		height: 25px;
		overflow: hidden;
		margin-bottom: 20px;
		margin-left: -2px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			height: 2px;
			width: 100%;
			top: 50%;
			left: 0;
			background: #696d83;
			border-radius: 3px;
		}

		&:before {
			transform: translateY(-50%) rotate(45deg);
		}

		&:after {
			transform: translateY(-50%) rotate(-45deg);
		}
	}

	.header-fixed__mob-menu-nav-link {
		position: relative;
		display: flex;
		align-items: center;
		padding: 10px 0;
		@include states {
			color: #fff;
		}
		font-size: 14px;
		transition: .3s;
		flex-direction: row-reverse;
	}

	.header-fixed__mob-menu-nav-link-img-c {
		position: relative;
		flex: 0 0 auto;
		margin-left: 16px;
	}

	.header-fixed__mob-menu-nav-link-img {
		max-width: 100%;
		height: auto;
		display: block;
	}
}

@media (max-width: 575px) {
	.section-title {
		font-size: 22px;
		line-height: 28px;
	}

	.categories__item,
	.recommend__item {
		width: 100%;
		margin-bottom: 60px;
	}

	.categories__list,
	.recommend__list {
		margin-bottom: -60px;
	}

	.categories__item {
		min-height: 280px;
		margin-bottom: 60px;
	}

	.categories__item-c {
		padding: 0 15px 25px 15px;
	}

	.categories__item-img-container {
		height: 70px;
		margin-bottom: 35px;
	}

	.categories__item-title {
		font-size: 20px;
	}

	.categories__item-desc {
		margin-bottom: 35px;
	}

	.btn-def {
		height: 40px;
		line-height: 38px;
		min-width: 125px;
		font-size: 14px;
	}

	.section-double-title .section-title {
		margin-bottom: 20px;
	}

	.footer {
		padding: 20px 0;
	}

	.footer-l {
		width: 100%;
		margin-bottom: 20px;
	}

	.footer-r {
		margin-left: 0;
	}

	.footer-r__text {
		text-align: left;
		font-size: 12px;
	}
}