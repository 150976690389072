@media (max-width: 991px) {
	.art-in-r__title {
		font-size: 28px;
	}

	.art-in-l {
		width: 220px;
		margin-right: 20px;
	}

	.art-in-l__buy-btn {
		height: 45px;
		line-height: 45px;
	}
}

@media (max-width: 767px) {
	.art-in-c {
		flex-wrap: wrap;
	}

	.art-in-l,
	.art-in-r {
		flex: 0 0 auto;
		width: 100%;
		margin-right: 0;
	}

	.art-in-l {
		margin-bottom: 35px;
	}

	.art-in,
	.p-art-in {
		padding-bottom: 50px;
	}
}

@media (max-width: 575px) { 
	.art-in-r__title {
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 5px;
	}

	.art-in-r__text {
		line-height: 26px;
	}
}