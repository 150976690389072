.footer {
	border: 1px solid rgba(#fff, 0.06);
	padding: 50px 0 60px;
}

.footer-c {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.footer__logo {
	display: block;
}

.footer__logo-img {
	max-width: 150px;
	height: auto;
}

.footer-r__text {
	line-height: 22px;
	font-size: 14px;
	text-align: right;
	color: rgba(#fff, 0.35);
}

.footer-r {
	margin-left: auto;
}