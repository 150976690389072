.header {
	position: relative;
	z-index: 1;
}

.header-top {
	padding-top: 50px;
	padding-bottom: 85px;

	> .container {
		position: relative;
	}
}

.header-top__logo-img {
	max-width: 150px;
	height: auto;
}

.header-fixed {
	transform: translateY(-105%);
	position: fixed;
	z-index: 10;
	left: -1px;
	top: -1px;
	right: -1px;
	background: #FFFFFF;
	box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.3), 0px 2px 4px rgba(0, 0, 0, 0.145494);
	transition: transform .3s;

	&.header-nav-is-fixed {
		transform: translateY(0);
	}
}

.header-fixed-c {
	display: flex;
	align-items: center;
}

.header-fixed-l {
	flex: 0 0 auto;
	margin-right: auto;
	padding-left: 30px;
}

.header-fixed__nav-list {
	display: flex;
}

.header-fixed__nav-item {
	flex: 0 0 auto;
}

.header-fixed__nav-link {
	position: relative;
	display: flex;
	align-items: center;
	height: 80px;
	line-height: 80px;
	border-left: 1px solid #F2F2F2;
	padding: 0 25px;
	@include states {
		color: rgba(#080C19, 0.72);
	}
	font-size: 14px;
	line-height: 22px;
	transition: .3s;

	&:hover,
	&:active {
		color: #fff;

		&:before {
			height: 100%;
		}

		.header-fixed__nav-link-img {
			&.header-fixed__nav-link-img-front {
				opacity: 0;
			}

			&.header-fixed__nav-link-img-back {
				opacity: 1;
			}
		}
	}

	span {
		position: relative;
	}

	&:before {
		position: absolute;
		display: block;
		content: "";
		left: 0;
		right: 0;
		top: 0;
		height: 2px;
		transition: .3s;
	}

	&_pink {
		&:before {
			background: linear-gradient(180.74deg, #C342D4 9.77%, #941FCE 100%);
		}
	}

	&_blue {
		&:before {
			background: linear-gradient(150.72deg, #0091CE 0%, #02D8C1 89.51%);
		}
	}

	&_green {
		&:before {
			background: linear-gradient(179.6deg, #FFBB00 11.85%, #00FFE4 91.52%);
		}
	}

	&_orange {
		&:before {
			background: linear-gradient(1.27deg, #EA9F7F 33.68%, #C12781 104.5%);
		}
	}
}

.header-fixed__nav-link-img-c {
	position: relative;
	margin-right: 16px;
	flex: 0 0 auto;
}

.header-fixed__nav-link-img {
	max-width: 100%;
	height: auto;
	transition: opacity .3s;
	display: block;

	&.header-fixed__nav-link-img-front {
		opacity: 1;
	}

	&.header-fixed__nav-link-img-back {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
}