.categories {
	padding-bottom: 160px;
}

.categories__list {
	padding-top: 25px;
	display: flex;
	flex-wrap: wrap;
	margin-left: -20px;
	margin-right: -20px;
}

.categories__item {
	padding-left: 20px;
	padding-right: 20px;
	min-height: 350px;
	flex: 0 0 auto;
	width: 25%;
}

.categories__item-wrap {
	position: relative;
	height: 100%;

	&:hover {
		.categories__item-bg {
			&.categories__item-bg_2 {
				transform: rotate(-3deg);
			}

			&.categories__item-bg_3 {
				transform: rotate(-7deg);
			}
		}
	}
}

.categories__item-c {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 15px 43px 15px;
	position: relative;
}

.categories__item-img-container {
	position: relative;
	margin-top: -25px;
	margin-bottom: 45px;
	height: 100px;
	margin-left: 15px;
}

.categories__item-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	margin: 0 auto;
}

.categories__item-title {
	font-size: 21px;
	line-height: 25px;
	font-weight: bold;
	text-align: center;
	max-width: 100%;
	margin-bottom: 20px;
	color: #fff;
}

.categories__item-desc {
	font-size: 14px;
	line-height: 22px;
	max-width: 100%;
	text-align: center;
	margin-bottom: 60px;
	color: rgba(#fff, 0.35);
}

.categories__item-btn-wrap {
	margin-top: auto;
	text-align: center;
}

.categories__item-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #250F50;
	border: 1px solid #3F2C65;
	transition: all 0.2s ease;
	backface-visibility: hidden;
}