.p-recommend {
	padding-top: 5px;
	position: relative;
	z-index: 1;
}

.header-top__light {
	display: block;
	width: 1090px;
	height: 1090px;
	border-radius: 50%;
	background: radial-gradient(505.76px at 50% 50%, #01B0C8 0%, rgba(1, 176, 200, 0.0001) 100%);
	position: absolute;
	left: -400px;
	top: -640px;
	z-index: -1;
	pointer-events: none;
}