.p-art-in {
	position: relative;
	padding-top: 20px;
	padding-bottom: 100px;
}

.art-in {
	padding-bottom: 100px;
}

.art-in-c {
	display: flex;
}

.art-in-l {
	flex: 0 0 auto;
	width: 300px;
	margin-right: 45px;
}

.art-in-r {
	flex: 1;
	min-width: 0;
	margin-top: -8px;
}

.art-in-l__buy-btn {
	display: block;
	margin-bottom: 0;
	background: #FFFFFF;
	width: 100%;
	box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15925);
	height: 55px;
	line-height: 55px;
	text-align: center;
	font-size: 16px;
	color: #080C19;
	font-weight: bold;
	position: relative;
	transition: .3s;

	&:hover,
	&:active,
	&:focus {
		color: #fff;
		background: #080C19;
	}
}

.art-in-l__img {
	margin-top: -1px;
	margin-bottom: 23px;
	width: 100%;
	display: block;
}

.art-in-l__author {
	width: 100%;
	margin-top: auto;
	display: flex;
	border: 1px solid rgba(255, 255, 255, 0.123103);
	padding: 5px 7px 5px 7px;
	background: rgba(255, 255, 255, 0.033758);
}

.art-in-l__author-l {
	flex: 0 0 auto;
	margin-right: 7px;
	align-self: flex-start;
	border-radius: 50%;
	border: 1px solid rgba(#fff, 0.02);
	padding: 2px;
}

.art-in-l__author-l-img-c {
	border-radius: 50%;
	border: 1px solid rgba(#fff, 0.05);
	padding: 2px;
}

.art-in-l__author-l-img-wrap {
	width: 35px;
	border-radius: 50%;
	overflow: hidden;
}

.art-in-l__author-r {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1;
	min-width: 0;
}

.art-in-l__author-r-title {
	line-height: 15px;
	font-size: 12px;
	color: #FFFFFF;
	max-width: 100%;
}

.art-in-l__author-r-desc {
	line-height: 15px;
	font-size: 12px;
	color: rgba(#fff, 0.35);
	max-width: 100%;
}

.art-in-r__title {
	line-height: 44px;
	margin-bottom: 0;
}

.art-in-r__desc {
	font-size: 14px;
	line-height: 22px;
	color: rgba(#fff, 0.35);
	margin-bottom: 20px;
}

.art-in-r__text {
	font-size: 14px;
	line-height: 31px;
	color: rgba(#fff, 0.85);

	p {
		margin-bottom: 25px;
	}

	ul {
		li {
			padding-left: 8px;
			position: relative;

			&:before {
				position: absolute;
				display: block;
				content: "-";
				left: 0;
			}
		}
	}
}