.recommend {
	padding-bottom: 100px;
}

.recommend__list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -20px -60px;
	padding-top: 25px;
}

.recommend__item {
	flex: 0 0 auto;
	width: 25%;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 60px;
}

.recommend__link {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.033758);
	border: 1px solid rgba(255, 255, 255, 0.13);
}

.recommend__item-img-c {
	position: relative;
	margin: -23px auto 10px;
	height: 160px;
	width: 100%;
}

.recommend__item-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	margin: 0 auto;
}

.recommend__item-title {
	font-size: 18px;
	line-height: 23px;
	font-weight: bold;
	text-align: center;
	max-width: 100%;
	margin-bottom: 10px;
	color: #fff;
	padding: 0 15px;
}

.recommend__item-desc {
	font-size: 14px;
	line-height: 22px;
	max-width: 100%;
	text-align: center;
	margin-bottom: 20px;
	color: rgba(255, 255, 255, 0.35);
	padding: 0 15px;
}

.recommend__item-bot {
	width: 100%;
	margin-top: auto;
	display: flex;
	border-top: 1px solid rgba(#fff, 0.13);
	padding: 5px 7px 5px 7px;
}

.recommend__item-bot-l {
	flex: 0 0 auto;
	margin-right: 7px;
	align-self: flex-start;
	border-radius: 50%;
	border: 1px solid rgba(#fff, 0.02);
	padding: 2px;
}

.recommend__item-bot-l-img-c {
	border-radius: 50%;
	border: 1px solid rgba(#fff, 0.05);
	padding: 2px;
}

.recommend__item-bot-l-img-wrap {
	width: 35px;
	border-radius: 50%;
	overflow: hidden;
}

.recommend__item-bot-r {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1;
	min-width: 0;
}

.recommend__item-bot-r-title {
	line-height: 15px;
	font-size: 12px;
	color: #FFFFFF;
	max-width: 100%;
}

.recommend__item-bot-r-desc {
	line-height: 15px;
	font-size: 12px;
	color: rgba(#fff, 0.35);
	max-width: 100%;
}