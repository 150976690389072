p {
	&:last-child {
		margin-bottom: 0;
	}
}

label {
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a,
a:hover,
a:focus {
	text-decoration: none;
	color: inherit;
	outline: none;
}

html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	line-height: normal;
	font-family: $f-Poppins;
	color: #fff;
	background: #080C19;
	transition: background 1s ease;
}

.main {
	flex: 1 0 auto;
	overflow-x: hidden;
}

.footer {
	flex: 0 0 auto;
	margin-top: auto;
}

.fw-bold {
	font-weight: bold;
}

.fw-extrbld {
	font-weight: 800;
}

.fw-black {
	font-weight: 900;
}

.fw-med {
	font-weight: 600;
}

.body-overflow {
	overflow: hidden;
}

.menu-overlay {
	display: block;
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99;
	visibility: visible;
	opacity: 0;
	pointer-events: none;
	transition: all .3s;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}
}

.btn-def {
	border: 1px solid rgba(255, 255, 255, 0.123103);
	background: rgba(255, 255, 255, 0.033758);
	outline: none;
	border-radius: 0;
	cursor: pointer;
	min-width: 136px;
	display: inline-block;
	height: 44px;
	line-height: 44px;
	@include states {
		color: rgba(#fff, 0.72);
	}
	text-align: center;
	text-transform: uppercase;
	padding: 0 10px;
	transition: .3s;

	&:hover,
	&:active {
		background-color: rgba(255, 255, 255, 0.2);
		border-color: rgba(255, 255, 255, 0.4);
	}
}

.section-title {
	font-size: 35px;
	line-height: 56px;
	color: #fff;
	margin-bottom: 40px;
	font-weight: bold;
}

.section-double-title {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.section-title {
		margin-right: auto;
	}
}

.section-double-title__btn {
	margin-bottom: 40px;
}

.wow {
	visibility: hidden;
}

.header-fixed__mob-menu {
	display: none;
	transform: translateX(-105%);
}

.header-fixed__menu-toggler {
	display: none;
}